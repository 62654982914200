<template>
  <Card>
    <template #content>
      <div id="drop-zone" class="p-2" v-if="Invoice.Items.length === 0">
        <div class="drop-zone" :class="{ 'hover': isDragging }" @dragover="dragOver" @dragleave="dragLeave"
             @drop="handleDrop">
          Arrastre su archivo XML hasta aquí
        </div>
        <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload" multiple>
      </div>
      <div class="p-2" v-else>
        <div class="flex justify-end mb-4">
          <Button label="Eliminar cargue" icon="pi pi-trash" @click="onReset"
                  class="p-button-raised p-button-danger p-button-rounded"/>
        </div>
        <div :class=" `${hidePedido ? 'grid-cols-1' : 'grid-cols-2' } grid gap-4`">
          <Card class="p-custom-card" v-if="!hidePedido">
            <template #title>PEDIDO {{ pedido.DocNum }}</template>
            <template #content>
              <div class="flex justify-between pb-4">
                <div>
                  <div style="font-size: smaller;">
                    <strong>Nombre Proveedor: </strong>{{ pedido.CardName }}
                  </div>
                  <div style="font-size: smaller;">
                    <strong>Fecha Pedido: </strong>{{ pedido.DocDate }}
                  </div>
                  <div style="font-size: smaller;">
                    <strong>Items Pedido: </strong>{{ pedido.ItemsQuantity }}
                  </div>
                </div>
                <div>
                  <Button label="Ocultar Pedido"
                          icon="pi pi-eye-slash" @click="viewPedido"
                          class="p-button-raised p-button-rounded"/>
                </div>
              </div>
              <DataTable
                :value="pedido.detalle"
                class="p-datatable-sm text-xs custom-scrollbar"
                showGridlines
                dataKey="Index"
                responsiveLayout="scroll"
                scrollHeight="500px"
                :scrollable="true"
                ref="tableList"
                removableSort
              >
                <template #empty>
                  No se encontraron datos registrados.
                </template>

                <Column field="Description" header="Descripción Articulo" style="min-width:28rem">
                  <template #body="{data}">
                    <div class="grid grid-cols-1 w-full">
                      <div class="flex justify-between items-center my-1">
                        <div>
                          <strong>Código Mx: </strong>{{ data.ItemCode }}
                        </div>
                        <div class="flex gap-2">
                          <Badge v-if="data.ReqMarca" :value="`Req. marca`"
                                 class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                          <Badge v-if="data.Controlado" value="Controlado"
                                 class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                          <Badge v-if="data.Regulado" value="Regulado"
                                 class=" bg-blue-300 text-red-blue text-xxs h-4 capitalize"></Badge>
                          <Badge v-if="data.cadenaFrio" value="Cadena Frio"
                                 class="bg-blue-800 text-blue text-xxs h-4"></Badge>
                        </div>
                      </div>
                      <div>
                        <strong>Nombre: </strong>{{ data.ItemName }}
                      </div>
                      <div>
                        <strong>Cod. Cum: </strong>{{ data.SWW }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column field="Quantity" header="Valores"
                        style="max-width:10rem">
                  <template #body="{ data }">
                    <div class="flex-col">
                      <div>
                        <strong>Cant: </strong><span> {{ $h.formatNumber(data.Quantity) }}</span>
                      </div>
                      <div>
                        <strong>Vlr Und: </strong><span> {{ $h.formatCurrency(data.Price) }}</span>
                      </div>
                      <div>
                        <strong>Vlr Total: </strong><span> {{ $h.formatCurrency(data.LineTotal) }}</span>
                      </div>
                    </div>
                  </template>
                </Column>
              </DataTable>
            </template>
            <template #footer>
              <div class="w-full p-2 grid grid-cols-1 lg:grid-cols-3 gap-8">
                <div class="grid text-xs grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-8 lg:col-span-2">
                  <div class="flex flex-col">
                    <p class="text-xs mb-2">
                      <strong>Comentarios: </strong>
                    </p>
                    <Textarea
                      class="p-inputtext-xs"
                      disabled
                      :autoResize="true"
                      v-model="pedido.Comments"
                      rows="3"
                      cols="20"
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="text-xs mb-2">
                      <strong>Entrada de diario: </strong>
                    </p>
                    <Textarea
                      class="p-inputtext-xs"
                      disabled
                      :autoResize="true"
                      v-model="pedido.JournalMemo"
                      rows="3"
                      cols="20"
                    />
                  </div>
                </div>
                <div class="text-xs lg:col-span-1">
                  <div class="flex justify-between border-b-2 pb-3">
                    <span class="text-gray-600">Sub Total Pedido: </span>
                    <span class="">{{ $h.formatCurrency(pedido.DocTotal - pedido.TaxTotal) }}</span>
                  </div>
                  <div class="flex justify-between my-3">
                    <span class="text-gray-600 mr-4">Total Descuento Documento:</span>
                    <span class="">{{ $h.formatCurrency(pedido.DiscSum) }}</span>
                  </div>
                  <div class="flex justify-between my-3">
                    <span class="text-gray-600">Total IVA:</span>
                    <span class="">{{ $h.formatCurrency(pedido.TaxTotal) }}</span>
                  </div>
                  <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
                    <span class="font-bold">Total Pedido:</span>
                    <span
                      class="">{{ $h.formatCurrency((pedido.DocTotal - pedido.DiscSum)) }}</span>
                  </div>
                </div>
              </div>
            </template>
          </Card>
          <Card class="p-custom-card">
            <template #title>FACTURA {{ Invoice.InvoiceNumber }}</template>
            <template #content>
              <div class="flex justify-between pb-4">
                <div>
                  <div style="font-size: smaller;">
                    <strong>Nombre Proveedor: </strong>{{ Invoice.CardName }}
                  </div>
                  <div style="font-size: smaller;">
                    <strong>Fecha Factura: </strong>{{ Invoice.DocDate }}
                  </div>
                  <div style="font-size: smaller;">
                    <strong>Items Factura: </strong>{{ Invoice.Items.length }}
                  </div>
                </div>
                <div>
                  <Button label="Mostrar Pedido" v-if="hidePedido"
                          icon="pi pi-eye" @click="viewPedido"
                          class="p-button-raised p-button-rounded"/>
                </div>
              </div>
              <div>
                <DataTable
                  :value="Invoice.Items"
                  class="p-datatable-sm text-xs custom-scrollbar"
                  showGridlines
                  dataKey="Index"
                  responsiveLayout="scroll"
                  scrollHeight="500px"
                  :scrollable="true"
                  ref="tableList"
                  removableSort
                >
                  <template #empty>
                    No se encontraron datos registrados.
                  </template>
                  <Column field="Description" header="Descripción Articulo" :style="`${hidePedido ? 'min-width:80rem' : 'min-width:32rem' }`">
                    <template #body="slotProps">
                      <div class="flex-col">
                        <div>
                          <strong>Nombre: </strong>
                        </div>
                        <div>
                          <span> {{ slotProps.data.Description }}</span>
                        </div>
                        <div>
                          <strong>Cod. Cum: </strong>{{ slotProps.data.CodCum }}
                        </div>
                        <div class="pt-2" v-if="slotProps.data.U_PHR_ItemName">
                          <strong>Medicamento en pedido: </strong>
                        </div>
                        <div>
                          <span>{{ slotProps.data.U_PHR_ItemName }}</span>
                        </div>
                        <div>
                          <strong>Medicamento en factura: </strong>
                        </div>
                        <div class="pt-1 w-72">
                          <Dropdown
                            optionLabel="Dscription"
                            optionValue="ItemCode"
                            v-model="slotProps.data.ItemCode"
                            :options="pedido.listSustitutos"
                            @change="onChange($event,slotProps)"
                            placeholder="Escriba 4 letras para buscar una agrupación..."
                            class="rounded-xs border-gray-300 max-w-md text-xs p-inputtext-xxs"
                            inputClass="p-inputtext-xs text-xxs"
                            panelClass="text-xxs"
                            @click="$h.xxsInput"
                            :filter="true"
                            @filter="getMX($event)"
                          />
                        </div>
                      </div>
                    </template>
                  </Column>
                  <Column field="BatchNumber" header="Lote"
                          :bodyStyle="`${Invoice.Tipo === 'Manual' ? 'justify-content: center; text-align: center' : ''}`"
                          style="max-width:12rem">
                    <template #body="{data}">
                      <div v-if="Invoice.Tipo === 'Manual'">
                        <div v-if="data.BatchNumbers.length === 0">
                          <Button type="button" class="p-button-xs" icon="pi pi-check" label="Asignar"
                                  @click="asignarLotes(data)"/>
                        </div>
                        <div v-if="data.BatchNumbers.length > 0 || $route.params.idRecepcionAdmin" class="flex justify-center">
                          <div class="mr-3">
                            <div>
                              <Button type="button" class="p-button-xs" icon="pi pi-eye" label="Ver"
                                      @click="toggle($event,data)" aria-haspopup="true"
                                      aria-controls="overlay_panel"/>
                              <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel"
                                            style="width: 500px" :breakpoints="{'960px': '75vw'}">
                                <DataTable
                                  :value="info"
                                  class="p-datatable-sm text-xs"
                                  showGridlines
                                  dataKey="id"
                                  scrollHeight="500px"
                                  :scrollable="true"
                                  responsiveLayout="scroll"
                                >
                                  <Column field="DistNumber" header="Lote" headerStyle="justify-content: center;"
                                          style="min-width:5rem" bodyStyle="text-align: center"/>
                                  <Column field="Quantity" header="Cant." headerStyle="justify-content: center;"
                                          style="min-width:2rem" bodyStyle="text-align: center">
                                    <template #body="{data}">
                                      <span>{{ $h.formatNumber(data.Quantity) }}</span>
                                    </template>
                                  </Column>
                                  <Column field="ExpDate" header="F.Venc."
                                          headerStyle="justify-content: center;"
                                          style="min-width:5rem" bodyStyle="text-align: center"/>
                                </DataTable>
                              </OverlayPanel>
                            </div>
                          </div>
                          <div>
                            <Button type="button" class="p-button-xs p-button-warning" v-tooltip.top="'Editar Lotes'"
                                    icon="pi pi-pencil" @click="asignarLotes(data)"/>
                          </div>
                        </div>
                      </div>
                      <div v-else class="flex-col ml-2" v-for="(item) in data.BatchNumbers" :key="item.indice">
                        <div>
                          <strong>Lote: </strong> <span> {{ item.DistNumber }}</span>
                        </div>
                        <div>
                          <strong>F.Venc: </strong> <span> {{ item.ExpDate }}</span>
                        </div>
                        <div>
                          <strong>Cant: </strong> <span> {{ item.Quantity }}</span>
                        </div>
                      </div>
                    </template>
                  </Column>
                  <Column field="Quantity" header="Valores"
                          style="max-width:10rem">
                    <template #body="{ data }">
                      <div class="flex-col ml-2">
                        <div>
                          <strong>Cant. Total: </strong><span> {{ $h.formatNumber(data.Quantity) }}</span>
                        </div>
                        <div>
                          <strong>Vlr Und: </strong><span> {{ $h.formatCurrency(data.Price) }}</span>
                        </div>
                        <div>
                          <strong>Vlr Total: </strong><span> {{ $h.formatCurrency(data.LineTotal) }}</span>
                        </div>
                      </div>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </template>
            <template #footer>
              <div class="w-full p-2 grid grid-cols-1 lg:grid-cols-3 gap-8">
                <div class="grid text-xs grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-8 lg:col-span-2">
                  <div class="flex flex-col">
                    <p class="text-xs mb-2">
                      <strong>Comentarios: </strong>
                    </p>
                    <Textarea
                      class="p-inputtext-xs"
                      :autoResize="true"
                      v-model="Invoice.Comments"
                      rows="3"
                      cols="20"
                    />
                  </div>
                  <div class="flex flex-col">
                    <p class="text-xs mb-2">
                      <strong>Entrada de diario: </strong>
                    </p>
                    <Textarea
                      class="p-inputtext-xs"
                      disabled
                      :autoResize="true"
                      v-model="Invoice.JournalMemo"
                      rows="3"
                      cols="20"
                    />
                  </div>
                </div>
                <div class="text-xs lg:col-span-1">
                  <div class="flex justify-between border-b-2 pb-3">
                    <span class="text-gray-600">Sub Total Factura: </span>
                    <span class="">{{ $h.formatCurrency(Invoice.DocTotalBeDi) }}</span>
                  </div>
                  <div class="flex justify-between my-3">
                    <span class="text-gray-600 mr-4">Total Descuento Documento:</span>
                    <span class="">{{ $h.formatCurrency(Invoice.DiscTotal) }}</span>
                  </div>
                  <div class="flex justify-between my-3">
                    <span class="text-gray-600">Total IVA:</span>
                    <span class="">{{ $h.formatCurrency(Invoice.TaxTotal) }}</span>
                  </div>
                  <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
                    <span class="font-bold">Total Factura:</span>
                    <span
                      class="">{{ $h.formatCurrency(Invoice.DocTotal) }}</span>
                  </div>
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
      <div class="pt-2 flex justify-end" v-if="Invoice.Items.length > 0">
        <Button label="Actualizar recepción" icon="pi pi-save" v-if="Invoice.Action === 'Edit'"
                class="p-button-raised p-button-success floating-button  p-button-rounded" @click="onEdit"/>
        <Button label="Crear recepción administrativa" icon="pi pi-save" v-else
                class="p-button-raised p-button-success floating-button  p-button-rounded" @click="onSubmit"/>
      </div>
    </template>
  </Card>
  <modalAsignarLotes ref="modalLotes"/>
</template>
<script>
import { parseString } from 'xml2js'
import { menuCollapse } from '@/utils/collapseMenu'
import { computed, defineAsyncComponent, onMounted, ref } from 'vue'
import Swal from 'sweetalert2'
import { useRoute } from 'vue-router'
import RecepcionPedidosService from '@/apps/pharmasan/compras/logistica/services/rcp-pedidos.service'

export default {
  name: 'xml',
  components: {
    modalAsignarLotes: defineAsyncComponent(() => import('./components/modalXmlLotes.vue'))
  },
  setup () {
    const _RecepcionPedidosService = new RecepcionPedidosService()
    const modalLotes = ref()
    const route = useRoute()
    const editingRows = ref([])
    const isDragging = ref(false)
    const op = ref()
    const listMd = ref([])
    const hidePedido = ref(false)
    const info = ref([])
    const pedido = ref({})
    const Invoice = ref({
      InvoiceNumber: null,
      CardName: null,
      DocDate: null,
      Comments: null,
      JournalMemo: null,
      DocTotal: null,
      DocTotalBeDi: null,
      TaxTotal: null,
      DiscTotal: 0,
      Items: []
    })

    const MdSeleccionados = computed(() => {
      if (!listMd.value) return []
      return listMd.value.filter((x) => !x.hide)
    })
    const handleFileUpload = (file) => {
      const reader = new FileReader()

      reader.onload = async () => {
        const xmlContent = reader.result
        const result = await convertXmlToJson(xmlContent)
        if (!result || !result.AttachedDocument) {
          await Swal.fire({
            icon: 'warning',
            title: 'Advertencia',
            text: 'El archivo ingresado no es XML válido para facturación electrónica por favor valide e inténtelo nuevamente',
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
          })
          return
        }
        const xmlDescription = await convertXmlToJson(result.AttachedDocument['cac:Attachment'][0]['cac:ExternalReference'][0]['cbc:Description'])
        console.log(xmlDescription.Invoice, '-->xml')
        Invoice.value.PedidoReferencia = xmlDescription.Invoice['cac:OrderReference'][0]['cbc:ID'][1]
        await getPedidoFactura(Invoice.value.PedidoReferencia)

        Invoice.value.Items = xmlDescription.Invoice['cac:InvoiceLine'].map((x, index) => {
          return {
            Index: index + 1,
            Description: x['cac:Item'][0]['cbc:Description'][0],
            // IdentificationItem: x['cac:Item'][0]['cac:BuyersItemIdentification'] ? x['cac:Item'][0]['cac:BuyersItemIdentification'][0]['cbc:ID'][0] : x['cac:Item'][0]['cac:StandardItemIdentification'] ? x['cac:Item'][0]['cac:StandardItemIdentification'][0]['cbc:ID'][0]._ : x['cac:Item'][0]['cbc:Description'][0],
            IdentificationItem: x['cac:Item'][0]['cbc:Description'][0],
            CodCum: x['cbc:Note'] ? x['cbc:Note'][0].split('|')[2] ?? 0 : 0,
            BatchNumbers: x['cac:Item'][0]['cac:ItemInstance'] ? x['cac:Item'][0]['cac:ItemInstance'][0]['cac:LotIdentification'].map((l) => {
              return {
                ExpDate: l['cbc:ExpiryDate'][0],
                DistNumber: l['cbc:LotNumberID'][0],
                Quantity: parseInt(x['cac:Price'][0]['cbc:BaseQuantity'][0]._) ?? 0
              }
            }) : [],
            InvoiceQuantity: parseInt(x['cbc:InvoicedQuantity'][0]._) ?? 0,
            Price: parseInt(x['cac:Price'][0]['cbc:PriceAmount'][0]._) ?? 0,
            LineTotal: parseInt(x['cbc:LineExtensionAmount'][0]._) ?? 0,
            ItemCode: null,
            U_PHR_ItemName: null,
            LineNum: null,
            DocEntry: null,
            DocNum: null
          }
        })
        Invoice.value.InvoiceNumber = xmlDescription.Invoice['cbc:ID'][0]
        // Invoice.value.PedidoReferencia = xmlDescription.Invoice['cbc:Note'][4]?.split('|')[3]?.split(' ')[1] === '' ? xmlDescription.Invoice['cbc:Note'][4]?.split('|')[3]?.split(' ')[3] : xmlDescription.Invoice['cbc:Note'][4]?.split('|')[3]?.split(' ')[2] ?? 240368
        Invoice.value.DocDate = xmlDescription.Invoice['cbc:IssueDate'][0]
        Invoice.value.DocTotal = parseInt(xmlDescription.Invoice['cac:LegalMonetaryTotal'][0]['cbc:LineExtensionAmount'][0]._)
        Invoice.value.DocTotalBeDi = parseInt(xmlDescription.Invoice['cac:LegalMonetaryTotal'][0]['cbc:LineExtensionAmount'][0]._)
        Invoice.value.TaxTotal = xmlDescription.Invoice['cac:TaxTotal'] ? parseInt(xmlDescription.Invoice['cac:TaxTotal'][0]['cbc:TaxAmount'][0]._) : 0
        // Invoice.value.TaxTotal = xmlDescription.Invoice['cac:TaxTotal'][0] ? parseInt(xmlDescription.Invoice['cac:TaxTotal'][0]['cbc:TaxAmount'][0]._) : 0
        Invoice.value.CardName = xmlDescription.Invoice['cac:AccountingSupplierParty'][0]['cac:Party'][0]['cac:PartyName'][0]['cbc:Name'][0]
        Invoice.value.Tipo = Invoice.value.Items.every((a) => a.BatchNumbers.length > 0) ? 'Auto' : 'Manual'
        // Invoice.value.Tipo = 'Auto'
        Invoice.value.JournalMemo = `Factura N° ${Invoice.value.InvoiceNumber} ${Invoice.value.CardName}`
        // console.log(xmlDescription.Invoice, '-->xml')
        console.log(Invoice.value, '-->prueba')
      }
      reader.readAsText(file)
    }
    const getMX = async ({ value }) => {
      // const params = {
      //   search: value.toUpperCase()
      // }
      // if (value.length >= 4) {
      //   _RecepcionPedidosService.searchMd(params).then(({ data }) => {
      //     data.forEach((element) => {
      //       if (!listMd.value.find((a) => a.ItemCode === element.ItemCode)) listMd.value.push(element)
      //     })
      //   })
      // }
    }

    const convertXmlToJson = (xmlContent) => {
      let resultado = ''
      parseString(xmlContent, (error, result) => {
        if (error) {
          resultado = ('Error al convertir XML a JSON:', error)
        } else {
          resultado = result
        }
      })
      return resultado
    }
    const dragOver = (event) => {
      event.preventDefault()
      isDragging.value = true
    }
    const dragLeave = (event) => {
      event.preventDefault()
      isDragging.value = false
    }
    const handleDrop = (event) => {
      event.preventDefault()
      isDragging.value = false
      const file = event.dataTransfer.files[0]
      if (file.type === 'text/xml') {
        handleFileUpload(file)
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'El archivo ingresado no es de tipo XML por favor valide e inténtelo nuevamente',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
      }
    }
    const viewPedido = () => {
      hidePedido.value = !hidePedido.value
    }
    const onReset = () => {
      Swal.fire({
        icon: 'question',
        title: '¿Esta seguro/a?',
        text: '¿Desea eliminar el contenido de esta tabla?',
        showConfirmButton: true,
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No, cancelar'
      }).then((resp) => {
        if (resp.isConfirmed) {
          Invoice.value.Items = []
        }
      })
    }
    const getPedidoFactura = (PedidoReferencia) => {
      _RecepcionPedidosService.getPedidoFactura({ DocNum: PedidoReferencia }).then(({ data }) => {
        pedido.value = data
        Invoice.value.CardCode = data.CardCode
      })
    }
    const onChange = ({ value }, { data }) => {
      const findItemIndex = pedido.value.detalle.findIndex((x) => x.ItemCode === value)
      if (findItemIndex === -1) {
        const findSustituto = pedido.value.listSustitutos.find((a) => a.ItemCode === value)
        const ItemAgrupa = pedido.value.detalle.find((l) => l.U_PHR_AgrupCom === findSustituto.U_PHR_AgrupCom)
        Invoice.value.Items.forEach((element) => {
          if (element.IdentificationItem === data.IdentificationItem) {
            element.LineNum = ItemAgrupa.LineNum
            element.ItemCode = ItemAgrupa.ItemCode
            element.U_PHR_ItemName = `${ItemAgrupa.ItemCode} - ${ItemAgrupa.ItemName}`
          }
        })
      } else {
        Invoice.value.Items.forEach((element) => {
          if (element.IdentificationItem === data.IdentificationItem) {
            element.LineNum = pedido.value.detalle[findItemIndex].LineNum
            element.ItemCode = pedido.value.detalle[findItemIndex].ItemCode
            element.U_PHR_ItemName = pedido.value.detalle[findItemIndex].ItemName
          }
        })
      }
    }
    const toggle = (event, data) => {
      info.value = data.BatchNumbers
      op.value.toggle(event)
    }
    const asignarLotes = (data) => {
      modalLotes.value.openModal(data)
    }
    const onSubmit = () => {
      if (!Invoice.value.Items.every((x) => x.LineNum !== null)) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Hay lineas por asignar, por favor revise e inténtelo de nuevo',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      if (!Invoice.value.Items.every((a) => a.BatchNumbers.length > 0)) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Hay lotes por asignar, por favor revise e inténtelo de nuevo',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      Invoice.value.Items.forEach((element) => {
        element.DocNum = pedido.value.DocNum
        element.DocEntry = pedido.value.DocEntry
      })
      _RecepcionPedidosService.postRecepcionAdmin(Invoice.value).then(({ data }) => {
        if (data.id) {
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: `Se creó la recepción administrativa Número: ${data.id} exitosamente`,
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
          }).then((response) => {
            Invoice.value.Items = []
            listMd.value = []
          })
        }
      })
    }
    const onEdit = async () => {
      if (!Invoice.value.Items.every((x) => x.LineNum !== null)) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Hay lineas por asignar, por favor revise e inténtelo de nuevo',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      if (!Invoice.value.Items.every((a) => a.BatchNumbers.length > 0)) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Hay lotes por asignar, por favor revise e inténtelo de nuevo',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      await _RecepcionPedidosService.updateRecepcionAdmin(Invoice.value).then(({ data }) => {
        if (data.id) {
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: `Se actualizó la recepción administrativa Número: ${data.id} correctamente`,
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true
          })
        }
        Invoice.value = data
      })
    }
    const getEdit = async () => {
      await _RecepcionPedidosService.getEditRecepcionAdmin(route.params.idRecepcionAdmin).then(({ data }) => {
        Invoice.value = data
      })
      await getPedidoFactura(Invoice.value.PedidoReferencia)
    }
    onMounted(async () => {
      menuCollapse()
      if (route.params.idRecepcionAdmin) await getEdit()
    })
    return {
      handleFileUpload,
      convertXmlToJson,
      handleDrop,
      dragLeave,
      dragOver,
      onReset,
      Invoice,
      isDragging,
      editingRows,
      modalLotes,
      asignarLotes,
      info,
      op,
      toggle,
      getPedidoFactura,
      pedido,
      hidePedido,
      viewPedido,
      listMd,
      getMX,
      MdSeleccionados,
      onChange,
      onSubmit,
      getEdit,
      onEdit
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep(.p-column-header-content) {
  justify-content: center;
}

.drop-zone {
  width: 400px;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  color: #888;
  line-height: 150px;
  margin: 0 auto;
}

.floating-button {
  position: fixed !important;
  bottom: 35px;
  right: 40px;
  z-index: 1050;
}

.drop-zone.hover {
  border-color: #aaa;
  background: #f8f8f8;
  color: #555;
}

::v-deep(.p-inputnumber) {
  width: 5rem;
}

::v-deep(.p-inputnumber-input) {
  width: 5rem;
}

.p-custom-card {
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 0px 3px 0 rgba(0, 0, 0, 0.19);
}

.custom-scrollbar ::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.custom-scrollbar ::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 5px;
}
</style>
